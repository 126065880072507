import React, { useEffect } from 'react';

const Toast = ({ type, message, onClose }) => {
    const types = {
        success: {
            icon: (
                <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
            ),
            bgColor: 'bg-green-100 dark:bg-green-800',
            textColor: 'text-green-500 dark:text-green-200',
        },
        error: {
            icon: (
                <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm4.95 13.607L13.607 15.95 10 12.343 6.393 15.95 4.95 14.607 8.557 11 4.95 7.393 6.393 5.95 10 9.557 13.607 5.95 15.05 7.393 11.443 11 15.05 14.607Z" />
                </svg>
            ),
            bgColor: 'bg-red-100 dark:bg-red-800',
            textColor: 'text-red-500 dark:text-red-200',
        },
        warning: {
            icon: (
                <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm-.75 4.75h1.5v6h-1.5Zm0 8h1.5v1.5h-1.5Z" />
                </svg>
            ),
            bgColor: 'bg-yellow-100 dark:bg-yellow-800',
            textColor: 'text-yellow-500 dark:text-yellow-200',
        },
    };

    // Kiểm tra nếu `message` có cấu trúc đặc biệt (object)
    const isStructuredMessage = typeof message === 'object' && message.port && message.domain;

    useEffect(() => {
        const timeout = isStructuredMessage ? 30000 : 10000;
        const timer = setTimeout(() => {
            onClose();
        }, timeout);

        return () => clearTimeout(timer);
    }, [onClose, isStructuredMessage]);

    const toastType = types[type];
    if (!toastType) {
        return null; // Không hiển thị nếu không hợp lệ
    }

    const { icon, bgColor, textColor } = toastType;

    return (
        <div
            className={`flex flex-col w-full max-w-sm p-4 mb-4 ${bgColor} rounded-lg shadow dark:text-gray-400 text-gray-500 z-50`}
            role="alert"
        >
            <div className="flex items-center">
                <div className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 ${textColor} rounded-lg`}>
                    {icon}
                </div>
                <div className="ml-3 text-sm font-semibold">{isStructuredMessage ? 'Warning Proxy' : message}</div>
                <button
                    type="button"
                    className="ms-auto -mx-1.5 -my-1.5 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1 hover:bg-gray-100 inline-flex items-center justify-center h-6 w-6 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                    </svg>
                </button>
            </div>
            {isStructuredMessage && (
                <div className="mt-2 text-sm">
                    <p>
                        <b>Port:</b> <span className="text-sm font-semibold text-red-800">{message.port}</span>
                    </p>
                    <p>
                        <b>Domain:</b> <span className="font-semibold text-red-800">{message.domain}</span>
                    </p>
                    <p>
                        <b>Upload:</b> <span className="font-semibold text-red-800">{message.upload} MB</span>
                    </p>
                    <p>
                        <b>Download:</b>
                        <span className="font-semibold text-red-800">{message.download} MB</span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default Toast;
