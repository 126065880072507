import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Toast from '../../common/toast';

const Modaltracking = ({ exportPort, onClose, onAddDomain }) => {

    const [logs, setLogs] = useState([]);
    const [domainRules, setDomainRules] = useState([]);
    const [wsClient, setWsClient] = useState(null); // Trạng thái cho WebSocket Client
    
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [proxyDataBytes, setProxyDataBytes] = useState(0); // Tổng số byte qua proxy
    const [proxyByPassBytes, setProxyByPassBytes] = useState(0); // Tổng số byte qua proxy
    const [showOnlyProxy, setShowOnlyProxy] = useState(false); // Trạng thái checkbox

    const [toast, setToast] = useState(null); // Quản lý Toast

    const showToast = (type, message) => {
        setToast({ type, message });
        setTimeout(() => setToast(null), 3000); // Tự động ẩn sau 3 giây
    };

    const domainStats = logs.reduce((acc, log) => {
        if (!acc[log.domain]) {
          acc[log.domain] = { upload: 0, download: 0 };
        }
        acc[log.domain].upload += log.uploadBytes;
        acc[log.domain].download += log.downloadBytes;
        return acc;
      }, {});

    const handleAddDomainClick = (domain) => {
        try {
            const isAdded = onAddDomain(domain); // Gọi hàm từ Bypass.js và nhận kết quả
            if (isAdded) {
                showToast('success', 'Domain added successfully!');
            } else {
                showToast('error', 'Domain already exists!');
            }
        } catch (error) {
            showToast('error', 'Failed to add domain!');
        }
    };

    // Fetch domain rules from API
    useEffect(() => {
        const fetchDomainRules = async () => {
            try {
                const response = await axios.get('https://bypass.saviartmedia.com/api/domain-rules/');
                const rules = response.data.map(rule => rule.rule);
                setDomainRules(rules);
                
            } catch (error) {
                console.error('Error fetching domain rules:', error);
            }
        };

        fetchDomainRules();
    }, []);
    const calculatePercentage = (part, total) => {
        if (total === 0) return '0%'; // Tránh chia cho 0
        return `${((part / total) * 100).toFixed(2)}%`;
    };
    // Connect WebSocket and handle incoming logs
    useEffect(() => {
        let reconnectTimeout;
      
        const connectWebSocket = () => {
          const ws = new WebSocket(`wss://bypass.saviartmedia.com/ws/logs/${exportPort}/`);
      
          ws.onopen = () => {
            console.log(`WebSocket connection opened for port: ${exportPort}`);
          };
      
          ws.onerror = (error) => {
            console.error(`WebSocket error for port: ${exportPort}`, error);
          };
      
          ws.onclose = (event) => {
            console.warn(`WebSocket connection closed for port: ${exportPort}`, event);
            if (event.code !== 1000) {
              console.log('Attempting to reconnect...');
              reconnectTimeout = setTimeout(() => connectWebSocket(), 5000); // Thử kết nối lại sau 5 giây
            }
          };
      
          ws.onmessage = handleWebSocketMessage;
      
          setWsClient(ws); // Lưu WebSocket client vào trạng thái
        };
      
        if (domainRules.length > 0) {
          connectWebSocket();
        }
      
        return () => {
          if (wsClient) wsClient.close(); // Đóng WebSocket hiện tại
          if (reconnectTimeout) clearTimeout(reconnectTimeout); // Xóa timeout nếu tồn tại
          console.log(`WebSocket closed for port: ${exportPort}`);
        };
      }, [exportPort, domainRules]);

    // Handle WebSocket messages
    const handleWebSocketMessage = (message) => {
        try {
            
            const dataFromServer = JSON.parse(message.data).message.trim(); // Parse dữ liệu từ WebSocket
            const logParts = dataFromServer.split(' ');

            // Kiểm tra dữ liệu hợp lệ
            if (logParts.length < 11) {
                console.error('Invalid WebSocket message format:', dataFromServer);
                return;
            }

            // Parse log
            const time = moment(logParts[0] + ' ' + logParts[1], 'DD-MM-YYYY HH:mm:ss').format(); // Time
            const connectType = logParts[2].split(':')[0]; // SOCK5
            const requestFrom = logParts[4].split(':')[0]; // 10.18.2.74
            const serverIpPort = logParts[5]; // 127.0.0.1:1000
            const uploadBytes = parseInt(logParts[6].split(':')[0], 10); // Upload (0)
            const downloadBytes = parseInt(logParts[6].split(':')[1], 10); // Download (0)
            const domain = logParts[9].split(':')[0]; // apple.com
            const dPort = parseInt(logParts[9].split(':')[1], 10); // 443 (D.Port)

            if (uploadBytes === 0 && downloadBytes === 0) {
              
                return;
            }
            // Format upload/download
            const upload = formatBytes(uploadBytes);
            const download = formatBytes(downloadBytes);

            // Determine bypass data
            const bypassData = serverIpPort.startsWith('127.0.0.1') ? 'Direct' : 'Proxy';

            // Determine rules
            let rules = 'None'; // Default nếu không khớp rule
            if (bypassData === 'Direct') {
                const matchedRule = domainRules.find((rule) => {
                    if (rule.startsWith('*') && rule.endsWith('*')) {
                        const pattern = rule.slice(1, -1);
                        return domain.includes(pattern);
                    } else if (rule.startsWith('*.')) {
                        const pattern = rule.slice(2);
                        return domain.endsWith(pattern) && domain !== pattern;
                    } else {
                        return domain === rule || domain.startsWith(`${rule}/`);
                    }
                });
                rules = matchedRule || 'None';
            }

            // Add log to state
            const newLog = {
                time,
                domain,
                dPort,
                requestFrom,
                uploadBytes, // Giá trị số để sắp xếp
                downloadBytes, // Giá trị số để sắp xếp
                upload,
                download,
                connectType,
                bypassData,
                rules,
            };

            if (bypassData === 'Proxy') {
                setProxyDataBytes((prevTotal) => prevTotal + uploadBytes + downloadBytes);
            }
            if (bypassData === 'Direct') {
                setProxyByPassBytes((prevTotal) => prevTotal + uploadBytes + downloadBytes);
            }


            setLogs((prevLogs) => [newLog, ...prevLogs]);
        } catch (error) {
            console.error('Error handling WebSocket message:', error);
        }
    };

    // Format bytes for upload/download
    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 B';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const unit = sizes[i];
        const value = (bytes / Math.pow(k, i)).toFixed(2);
        return i === 0 ? `${(bytes / k).toFixed(2)} KB` : `${value} ${unit}`;
    };


    const handleSort = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {
                // Đổi hướng sắp xếp nếu đang sắp xếp theo cùng một cột
                return { key, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
            }
            // Sắp xếp tăng dần nếu là cột mới
            return { key, direction: 'asc' };
        });
    };

  // Lọc logs dựa trên trạng thái checkbox
  const filteredLogs = showOnlyProxy
  ? logs.filter((log) => log.bypassData === 'Proxy')
  : logs;


  const sortedLogs = React.useMemo(() => {
    // Áp dụng lọc trước khi sắp xếp
    const displayLogs = showOnlyProxy
        ? logs.filter((log) => log.bypassData === 'Proxy')
        : logs;

    if (!sortConfig.key) return displayLogs;

    return [...displayLogs].sort((a, b) => {
        const valueA = sortConfig.key === 'upload' ? a.uploadBytes :
            sortConfig.key === 'download' ? a.downloadBytes :
                a[sortConfig.key];
        const valueB = sortConfig.key === 'upload' ? b.uploadBytes :
            sortConfig.key === 'download' ? b.downloadBytes :
                b[sortConfig.key];

        if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    });
}, [logs, sortConfig, showOnlyProxy]);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative w-5/6 bg-gray-100 rounded-lg shadow h-5/6 dark:bg-gray-800 ">
                {/* Header */}
                <div className="flex items-center justify-between p-2 border-b rounded-t bg-gradient-to-l from-gray-500 to-black-500">
                    <div className="flex gap-1 text-sm font-semibold text-gray-900 dark:text-white">
                        Tracking Proxy: {exportPort}
                        <span className="relative flex w-3 h-3">
                            <span className="absolute inline-flex w-full h-full bg-green-400 rounded-full opacity-75 animate-ping"></span>
                            <span className="relative inline-flex w-3 h-3 bg-green-500 rounded-full"></span>
                        </span>
                    </div>
                    <button onClick={onClose} className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-red-200 hover:text-gray-900 ms-auto dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="grid w-full p-1 mb-0 statistics gap-y-5 gap-x-6 md:grid-cols-1 xl:grid-cols-2">
                    <div className='grid md:grid-cols-1 xl:grid-cols-2 gap-y-5 gap-x-6 '>
                        <div className="items-stretch self-stretch justify-between gap-5 pt-2 pl-4 pr-4 bg-white rounded-lg drop-shadow-sm max-md:pr-5">
                            <div className="flex items-center justify-between p-1">
                                <div className="flex">
                                    <p className='text-2xl font-bold text-'>
                                        {calculatePercentage(proxyDataBytes, proxyDataBytes + proxyByPassBytes)}
                                    </p>

                                </div>
                                <div className="flex-col items-end text-right">
                                    <p className="block text-sm font-semibold ">Bandwidth Use</p>
                                    <p className="block text-exs ">Dung lượng sử dụng Proxy</p>
                                    <h4 className="flex items-center justify-end gap-2 text-xl font-semibold leading-snug text-emerald-600/">
                                        {formatBytes(proxyDataBytes)}


                                    </h4>

                                </div>
                            </div>
                            <div className="p-1 border-t">
                                <p className="block font-sans text-sm antialiased font-normal leading-relaxed ">

                                </p>
                            </div>
                        </div>
                        <div className="items-stretch self-stretch justify-between gap-5 pt-2 pl-4 pr-4 bg-white rounded-lg drop-shadow-sm max-md:pr-5">
                            <div className="flex items-center justify-between p-1">
                                <div className="flex">
                                    <p className='text-2xl font-bold text-'>
                                        {calculatePercentage(proxyByPassBytes, proxyDataBytes + proxyByPassBytes)}
                                    </p>

                                </div>
                                <div className="flex-col items-end text-right">
                                    <p className="block text-sm font-semibold ">Bandwidth Bypass Use</p>
                                    <p className="block text-exs ">Dung lượng đã được Bypass</p>
                                    <h4 className="flex items-center justify-end gap-2 text-xl font-semibold leading-snug text-emerald-600/">
                                        {formatBytes(proxyByPassBytes)}


                                    </h4>
                                </div>
                            </div>
                            <div className="p-1 border-t">
                                <p className="block font-sans text-sm antialiased font-normal leading-relaxed ">

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <div className="flex items-center w-2/5 border border-gray-200 rounded ps-4 dark:border-gray-700">
                            <input id="bordered-checkbox-1" type="checkbox" onChange={(e) => setShowOnlyProxy(e.target.checked)} value="" name="bordered-checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label  className="w-full py-4 text-sm font-medium text-gray-900 ms-2 dark:text-gray-300">Display Only Traffic From Proxy</label>
                        </div>
                        
                    </div>
                </div>


                {/* Nội dung */}
                <div className='h-[600px] overflow-y-auto'>

                    <div className="grid w-full p-1 mt-0mb-0 statistics gap-y-5 gap-x-6 md:grid-cols-1 xl:grid-cols-1 ">
                        <div className='flex flex-col w-full bandwidth_table'>
                            <table id="bandwidth-table" className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                <thead className="bg-gray-50 dark:bg-neutral-700">
                                    <tr>
                                        <th onClick={() => handleSort('domain')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Domain {sortConfig.key === 'domain' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('port')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            D.Port {sortConfig.key === 'port' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('requestFrom')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Request From {sortConfig.key === 'requestFrom' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('upload')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Upload {sortConfig.key === 'upload' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('download')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Download {sortConfig.key === 'download' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>

                                        <th onClick={() => handleSort('connectType')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Connect {sortConfig.key === 'connectType' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('bypassData')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Bypass Data {sortConfig.key === 'bypassData' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('rules')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Rules {sortConfig.key === 'rules' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th onClick={() => handleSort('time')} className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Time {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</th>
                                        <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase cursor-pointer text-start dark:text-neutral-500">
                                            Action </th>

                                    </tr>
                                </thead>
                                <tbody className=''>

                                    {sortedLogs.map((log, index) => (
                                        <tr key={index} className="font-mono border border-dashed border-e-gray-400 ">
                                              <td
                className="relative px-4 py-1 text-gray-900 whitespace-nowrap dark:text-white group"
              >
                {/* Domain với Tooltip */}
                <span className="cursor-pointer group-hover:text-blue-500">{log.domain}</span>
                {/* Tooltip */}
                <div
                  className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                  style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', marginTop: '4px' }}
                >
                  <p>
                    <b>Upload:</b> {formatBytes(domainStats[log.domain]?.upload)}
                  </p>
                  <p>
                    <b>Download:</b> {formatBytes(domainStats[log.domain]?.download)}
                  </p>
                </div>
              </td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.dPort}</td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.requestFrom}</td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.upload}</td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.download}</td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.connectType}</td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">
                                                {log.bypassData === "Proxy" ? (
                                                    <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                                        Proxy
                                                    </span>
                                                ) : (
                                                    <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                                        Direct
                                                    </span>
                                                )}
                                            </td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{log.rules}</td>
                                            <td className="px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white">{moment(log.time).fromNow()}</td>
                                            <td className='px-4 py-1 text-sm text-gray-900 whitespace-nowrap dark:text-white'>
                                                <button
                                                    onClick={() => handleAddDomainClick(log.domain)}
                                                    className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                                                >
                                                    Add
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>



                {/* Footer */}
                <div className="flex justify-end mt-6">

                </div>
                {/* Hiển thị Toast */}
                {toast && (
                    <div className="absolute bottom-4 right-4">
                        <Toast
                            type={toast.type}
                            message={toast.message}
                            onClose={() => setToast(null)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modaltracking;
